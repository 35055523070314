<template>
	<div class="c-app flex-row align-items-center authen-page">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol md="8">
					<CCardGroup>
						<!-- <CCard
							color="primary"
							text-color="white"
							class="text-center py-5 col-4 d-md-down-none"
							body-wrapper
						>
							<CCardBody class="h-100">
								<img v-lazy="logo" alt class="logo" />
							</CCardBody>
						</CCard> -->
						<CCard class="p-4">
							<CCardBody>
								<ValidationObserver ref="form" v-slot="{ handleSubmit }">
									<form @submit.prevent="handleSubmit(forgotPwd)">
										<h1 class="pb-4 m-0">
											{{ $t("ForgotPwd.Title") }}
										</h1>
										<div class="description pb-4 text-black-50">
											{{ $t("ForgotPwd.Description") }}
										</div>
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="auth_email"
												rules="requiredEmail|isEmail"
												mode="lazy"
											>
												<div :class="classes">
													<input
														v-model.trim="email"
														type="text"
														class="form-control"
														autocomplete="username email"
														:placeholder="$t('ForgotPwd.Email')"
														:maxlength="maxLength"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</ValidationProvider>
										</div>

										<CRow>
											<CCol col="6" class="text-left">
												<button class="btn btn-outline-dark" type="button" @click="backtoLogin">
													{{ $t("ForgotPwd.Login") }}
												</button>
											</CCol>
											<CCol col="6" class="text-right">
												<button class="px-4 btn btn-outline-custom" type="submit">
													{{ $t("ForgotPwd.Send") }}
												</button>
											</CCol>
										</CRow>
									</form>
								</ValidationObserver>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>
import logo from "@/assets/img/logo_small.jpg"
import { FORGOT_PASSWORD } from "../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("authentication")

export default {
	name: "ForgotPwd",

	data() {
		return {
			logo,
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,

			email: null,
		}
	},

	computed: {},

	watch: {},

	methods: {
		...mapActions({ FORGOT_PASSWORD }),

		forgotPwd() {
			if (this.email) this.FORGOT_PASSWORD({ email: this.email })
		},

		backtoLogin() {
			this.$router.push("/login")
		},
	},
}
</script>
